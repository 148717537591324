import styled from "styled-components";
export const TextFooter = styled.h1`
    text-align: center;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Poppins';

`   