import { TextFooter } from "./style"
const gab = "https://www.instagram.com/_dias17y/"


function Fotter () {
    return (
        <div>
            <TextFooter> Desenvolvido com ❤️ por  <br></br><a href={gab}>Gabriel</a></TextFooter>
        </div>
    )
}
export {Fotter}