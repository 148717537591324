import React from 'react';
import { Home } from './pages/Home/Home';

function App() {
  return (
    
    <div>
      <Home /> 
    </div>
  );
}

export default App;
